import request from "./request";

// 获取表头个人信息
export function getUserInfo(data) {
  return request({
    url: "/client/workbench/userInfo",
    method: "get",
  });
}

// 竞拍列表
export function getAuctionList(data) {
  return request({
    url: "/client/workbench/list",
    method: "get",
    params: data,
  });
}

// 成功得标
export function getSuccessList(data) {
  return request({
    url: "/client/workbench/wonBidList",
    method: "get",
  });
}

// 拍卖列表
export function getAuctionInventoryList(data) {
  return request({
    url: "/client/workbench/auctionList",
    method: "get",
    params: data,
  });
}

// 申请列表
export function getApplicationList(data) {
  return request({
    url: "/client/workbench/applicationAwait",
    method: "get",
  });
}
// 申请列表
export function cancelApplication(data) {
  return request({
    url: "/client/workbench/updateStatus",
    method: "get",
    params: data,
  });
}

// 删除文件/图片
export function deleteFile(data) {
  return request({
    url: "/file/deleteFile",
    method: "get",
    params: data,
  });
}

// ------------------------发起拍卖申请
// 步骤1获取基础信息
export function getBaseInfo() {
  return request({
    url: "/client/auction/userInfo",
    method: "get",
  });
}

// 校验验证码
export function validateSms(data) {
  return request({
    url: "/client/auction/validateSms",
    method: "post",
    data: data,
  });
}

// 步骤2，提交拍卖申请
export function insertAuction(data) {
  return request({
    url: "/client/auction/insert",
    method: "post",
    data: data,
  });
}

// 步骤三回显
export function getApplicationInfo(data) {
  return request({
    url: "/client/auction/applicationInfo",
    method: "get",
    params: data,
  });
}

// 步骤三更新申请
export function updateApplication(data) {
  return request({
    url: "/client/auction/updateApplication",
    method: "post",
    data: data,
  });
}

// 步骤四确认方案列表
export function confirmAuctionList(data) {
  return request({
    url: "/client/auction/confirmAuctionList",
    method: "get",
    params:data
  });
}

// 步骤四确认方案
export function confirmUpdateStatus(data) {
  return request({
    url: "/system/AuctionApplication/confirm",
    method: "post",
    data: data,
  });
}

// 步骤五获取保证金
export function getTotalDeposit(data) {
  return request({
    url: "/client/auction/getTotalDeposit",
    method: "get",
    params: data,
  });
}

// 撤回申请
export function withdrawUpdateStatus(data) {
  return request({
    url: "/system/AuctionApplication/updateStatus",
    method: "post",
    data: data,
  });
}


// 获取公告详情

export function viewBidAnnouncementById(data) {
  return request({
    url: "/client/auction/viewBidAnnouncementById",
    method: "get",
    params: data,
  });
}

// 获取竞拍数字加待处理
export function bidCount() {
  return request({
    url: "/client/workbench/bidCount",
    method: "get",
  });
}

// 获取拍卖数字
export function auctionCount() {
  return request({
    url: "/client/workbench/auctionCount",
    method: "get",
  });
}

// 竞拍成功得标付款
export function bidPay(data) {
  return request({
    url: "/client/transaction/bidPay",
    method: "post",
    data
  });
}

// 拍卖列表费用支付
export function auctioneerPay(data) {
  return request({
    url: "/client/transaction/auctioneerPay",
    method: "post",
    data
  });
}

// 拍卖申请支付保证金

export function auctioneerPayDeposit(data) {
  return request({
    url: "/client/transaction/auctioneerPayDeposit",
    method: "post",
    data
  });
}

// 删除信息
export function auctionDelete(params) {
  return request({
    url: "/client/auction/delete?applicationNumber="+params,
    method: "get",
  });
}

// 我的工作台进入竞拍大厅
export function enterBidHall(params) {
  return request({
    url: "/client/workbench/enterBidHall",
    method: "get",
  });
}



// 查看合同
export function viewContract(params) {
  return request({
    url: "/platform/identity/downLoadContract",
    method: "get",
    params
  });
}